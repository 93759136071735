import React, {useContext, useEffect, useState} from "react";
import {Box, Button, Card, CardActions, CardContent, Grid, Tooltip, Typography, Checkbox, FormControl, FormControlLabel, FormHelperText} from "@mui/material";
import {useReservationAPI} from "../../service/useReservationAPI";
import {GlobalStateContext} from "../../state/GlobalStateProvider";
import PetsIcon from "@mui/icons-material/Pets";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import Loading from "../common/Loading";
import GetErrorSnackbar from "../../common/Snackbar";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {useTranslation} from "react-i18next";

function ReservationOverview({updateCurrentStep}) {
    const {t} = useTranslation();
    const {getReservationByUuid, getPaymentLink} = useReservationAPI();
    const {globalState} = useContext(GlobalStateContext);
    const [reservation, setReservation] = useState({
        bookingNumber: '',
        price: '',
        cats: [],
        arrival: '',
        departure: '',
        practice: '',
    });
    const [isLoading, setIsLoading] = useState(true);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [termsOfUse, setTermsOfUse] = useState(false);
    const [errors, setErrors] = useState({});

    function fetch() {
        setIsLoading(true);
        getReservationByUuid(globalState.reservationUuid)
            .then(value => {
                setReservation(value)
            })
            .catch(() => handleFetchError(t("fetchReservationError")))
            .finally(() => setIsLoading(false));
    }

    function handleFetchError(reason) {
        setSnackbarMessage(reason);
        setOpenSnackbar(true);
    }

    useEffect(() => {
        fetch();
    }, []);

    function goBack() {
        updateCurrentStep(6);
    }

    function getPaymentLinkCall() {
        if (validateForm()) {
            getPaymentLink(globalState.reservationUuid)
                .then(value => {
                    // Before redirecting to payment provider
                    localStorage.setItem('paymentState', {state: 'OPEN', uuid: JSON.stringify(globalState.reservationUuid)});
                    window.location.href = value;
                })
                .catch(() => handleFetchError(t("fetchPaymentLinkError")));
        }
    }

    const validateForm = () => {
        let tempErrors = {};
        tempErrors.termsOfUse = termsOfUse === true ? "" : t('termsOfUseRequired');
        setErrors({...tempErrors});
        return Object.values(tempErrors).every(x => x === "");
    };

    return (
        <>
            {isLoading && <Loading/>}
            {!isLoading && (
                <Grid container alignItems="center" justifyContent="center" spacing={1} sx={{textAlign: 'center'}}>
                    <Grid item xs={12} sm={10} md={8} lg={6}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="h5" color="secondary">
                                    <h1>{t("reservationOverviewTitle")}</h1>
                                </Typography>
                                <Typography color="secondary" display="block">
                                    <b>{t("reservationNumber")}</b>
                                </Typography>
                                <Typography color="secondary" display="block" style={{marginBottom: '15px'}}>
                                    {reservation.bookingNumber}
                                </Typography>
                                <Typography color="secondary" display="block">
                                    <b>{t("reservationPeriod")}</b>
                                </Typography>
                                <Typography color="secondary" display="block">
                                    {reservation.fromDate} - {reservation.toDate}
                                </Typography>
                                <Typography color="secondary" display="block" style={{marginTop: '15px'}}>
                                    <b>{t("veterinaryPractice")}</b>
                                </Typography>
                                <Typography color="secondary" display="block" style={{marginBottom: '15px'}}>
                                    {reservation.practice}
                                </Typography>

                                <Typography color="secondary" display="block">
                                    <b>{t("reservationFor")}</b>
                                </Typography>
                                {reservation.cats.map((cat) => (
                                    <Typography key={cat.name} color="secondary" display="block">
                                        <PetsIcon color="primary"/> {cat.name}
                                    </Typography>
                                ))}
                                <Typography color="secondary" display="block">
                                    <b>{t("checkIn")}</b>
                                </Typography>
                                <Typography color="secondary" display="block" style={{marginBottom: '15px'}}>
                                    {reservation.checkIn}
                                </Typography>
                                <Typography color="secondary" display="block">
                                    <b>{t("checkOut")}</b>
                                </Typography>
                                <Typography color="secondary" display="block" style={{marginBottom: '15px'}}>
                                    {reservation.checkOut}
                                </Typography>
                                <Typography color="secondary" display="block">
                                    <b>{t("selectedStay")}</b>
                                </Typography>
                                <Typography color="secondary" display="block" style={{marginBottom: '15px'}}>
                                    {reservation.stayTypes}
                                </Typography>
                                <Tooltip title={t("serviceFeeIncluded")}>
                                    <Box display="inline-flex" alignItems="center">
                                        <Typography variant="body1" color="main" display="block">
                                            <b>{t("reservationPrice")}</b>
                                        </Typography>
                                        <InfoOutlinedIcon style={{marginLeft: 4}}/>
                                    </Box>
                                </Tooltip>
                                <Typography color="secondary" display="block" style={{marginBottom: '15px'}}>
                                    {reservation.price} €
                                </Typography>
                                <Typography color="secondary" display="block">
                                    <b>{t("advance")}</b>
                                </Typography>
                                <Typography color="secondary" display="block" style={{marginBottom: '15px'}}>
                                    {reservation.advance} €
                                </Typography>
                                <FormControl
                                    component="fieldset"
                                    variant="standard"
                                    margin="normal"
                                    error={!!errors.termsOfUse}
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                required
                                                checked={termsOfUse}
                                                onChange={() => {
                                                    setTermsOfUse(!termsOfUse);
                                                }}
                                                name='termsOfUse'
                                            />}
                                        label={
                                            <span>
                                                <a
                                                    href="https://www.kattenhotel-catland.be/huisregels.html"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    style={{color: 'blue', textDecoration: 'underline'}}
                                                >
                                                    {t('agreeTermsOfUse')}
                                                </a>
                                            </span>
                                        }
                                    />
                                    {errors.termsOfUse && (
                                        <FormHelperText>{errors.termsOfUse}</FormHelperText>
                                    )}
                                </FormControl>
                            </CardContent>
                            <CardActions style={{display: 'flex', justifyContent: 'space-between'}}>
                                <Button onClick={goBack} variant="contained">
                                    {t("previous")}
                                </Button>
                                <Button variant="contained" onClick={getPaymentLinkCall}>
                                    {t("confirmReservation")}
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <GetErrorSnackbar openSnackbar={openSnackbar} snackbarMessage={snackbarMessage} setOpenSnackbar={setOpenSnackbar}/>
                </Grid>
            )}
        </>
    );
}

export default withAuthenticationRequired(ReservationOverview, {
    onRedirecting: () => <Loading/>,
    returnTo: '/nieuwe-reservatie'
});
