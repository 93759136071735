import {Alert, Button, Card, CardActions, CardContent, CardHeader, Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography} from "@mui/material";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import Loading from "../../common/Loading";
import React, {useContext} from "react";
import {GlobalStateContext} from "../../../state/GlobalStateProvider";
import {useTranslation} from "react-i18next";

function CheckOutHour({checkOutHours, updateReservationStep, handleReserve, isLoadingInParent}) {

    const { t } = useTranslation();
    const {globalState, updateGlobalState} = useContext(GlobalStateContext);

    return (
        <Card>
            <CardHeader title={<Typography color="secondary" variant="h5">{t('checkOutHourTitle')}</Typography>}/>
            <CardContent>
                <TableContainer component={Paper} sx={{marginTop: 5}}>
                    <Table>
                        <TableBody>
                            {checkOutHours.map((pickUpHour) => (
                                <TableRow key={pickUpHour} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell component="th" scope="row">
                                        {/* Example: Uncomment and replace {cat.name} with your cat name variable if needed */}
                                        {/* <PetsIcon color="primary" sx={{ marginRight: '10px' }} /> {cat.name} */}
                                        <Checkbox
                                            checked={pickUpHour === globalState.reservationMeta.checkOutHour}
                                            onChange={() => {
                                                updateGlobalState({reservationMeta: {checkOutHour: (pickUpHour === globalState.reservationMeta.checkOutHour ? null : pickUpHour)}})
                                            }}
                                            name={pickUpHour.toString()}
                                            color="primary"
                                        />
                                        <Typography variant="body1" color="secondary"><b>{pickUpHour}h</b></Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        {checkOutHours.length === 0 && (
                            <Alert sx={{marginTop: 2}} severity="warning">{t('noCheckOutHoursAvailable')}</Alert>
                        )}
                    </Table>
                </TableContainer>
            </CardContent>
            <CardActions style={{
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <>
                    <Button onClick={() => updateReservationStep(4)} variant="contained">
                        {t('previous')}
                    </Button>
                    {checkOutHours.length !== 0 ?
                        (
                            <Button disabled={!globalState.reservationMeta.checkOutHour} onClick={handleReserve} variant="contained">
                                {t('next')}
                            </Button>
                        ) : (
                            <Button onClick={handleReserve} variant="contained">
                                {t('next')}
                            </Button>)}
                </>
            </CardActions>
        </Card>
    )
}

export default withAuthenticationRequired(CheckOutHour, {
    onRedirecting: () => <Loading/>,
    returnTo: '/nieuwe-reservatie'
});
