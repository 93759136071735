export function toLocalDate(startDate) {
    return `${startDate.getFullYear()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getDate().toString().padStart(2, '0')}`;
}

export function toLocalDateReversed(startDate) {
    return `${startDate.getDate().toString().padStart(2, '0')}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getFullYear()}`;
}

export function getDateStringToAvoidTimezone(value) {
    const year = value.getFullYear();
    const month = String(value.getMonth() + 1).padStart(2, '0');
    const day = String(value.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export function formatDateTime(date, hour) {
    const newDate = new Date(date);

    // Format the date
    const day = String(newDate.getDate()).padStart(2, '0');
    const month = String(newDate.getMonth() + 1).padStart(2, '0');
    const year = newDate.getFullYear();

    let formattedTime = null;
    if(hour !== null) {
        formattedTime = hour.substring(0, 5);
    }

    return `${day}-${month}-${year} ${formattedTime}`;
}

export function formatDate(date) {
    if(!date) {
        return '';
    }

    const newDate = new Date(date);

    // Format the date
    const day = String(newDate.getDate()).padStart(2, '0');
    const month = String(newDate.getMonth() + 1).padStart(2, '0');
    const year = newDate.getFullYear();

    return `${day}-${month}-${year}`;
}

export function formatHour(hour) {
    let formattedHour = null;
    if(hour !== null) {
        formattedHour = hour.substring(0, 5);
    }

    return formattedHour;
}

export function parseFormattedDate(dateStr) {
    if (!dateStr) return null;

    const [datePart, timePart] = dateStr.split(' ');
    const [day, month, year] = datePart.split('-').map(Number);

    // If time is "null", treat it as '00:00'
    const [hours, minutes] = (timePart && timePart !== "null") ? timePart.split(':').map(Number) : [0, 0];

    return new Date(year, month - 1, day, hours, minutes);
}
