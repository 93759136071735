import React, {useEffect, useState} from "react";
import {Box, CssBaseline, Grid, ThemeProvider, useMediaQuery} from "@mui/material";
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import {GlobalStateProvider} from "./state/GlobalStateProvider";
import theme from './common/Theme';
import {Auth0Provider, useAuth0} from "@auth0/auth0-react";
import {getConfig} from "./config";
import ReservationManagement from "./components/management/ReservationManagement";
import NewReservationsOverview from "./components/management/NewReservationsOverview";
import SideNav from "./components/common/SideNav";
import TopBar from "./components/common/TopBar";
import NewReservation from "./components/user/NewReservation";
import PersonForm from "./components/user/PersonForm";
import CatInformationForm from "./components/user/CatInformationForm";
import MyReservations from "./components/user/MyReservations";
import VeterinaryForm from "./components/user/VeterinaryForm";
import ProtectedRoute from "./components/security/ProtectedRoute";
import PaymentProcessor from "./components/user/PaymentProcessor";
import NewReservationPlanner from "./components/user/NewReservationPlanner";
import Login from "./components/common/Login";
import PickUpDeliverySchedules from "./components/management/PickUpDeliverySchedules";
import EmergencyContactForm from "./components/user/EmergencyContact";
import ServiceFee from "./components/management/ServiceFee";
import './i18n';
import Vacations from "./components/management/Vacations";
import AddReservation from "./components/management/AddReservation";

function App() {
    // Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider
    const config = getConfig();

    const onRedirectCallback = (appState) => {
        window.history.replaceState({}, document.title, appState?.returnTo || window.location.pathname);
    };


    // In order to receive a JWT you must include an audience parameter with your token request.
    const providerConfig = {
        domain: config.domain,
        clientId: config.clientId,
        onRedirectCallback,
        authorizationParams: {
            redirect_uri: window.location.origin,
            ...(config.audience ? {audience: config.audience} : null),
        },
        useRefreshTokens: true,
        cacheLocation: 'localstorage',
    };

    const reservationUuid = '';
    const drawerWidth = 240;
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div className="App">
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <Router>
                    <Auth0Provider {...providerConfig}>
                        <Box sx={{display: 'flex'}}>
                            <GlobalStateProvider>
                                <TopBar/>
                                <SideNav/>
                                <Box
                                    component="main"
                                    sx={{
                                        flexGrow: 1,
                                        p: 3,
                                        marginLeft: `${isMobile ? 0 : drawerWidth}px`,
                                        width: {sm: `calc(100% - ${drawerWidth}px)`}
                                    }}
                                >
                                    <Grid container spacing={2} sx={{mt: 8}}> {/* Adjust the margin-top (mt) as needed */}
                                        <Grid item xs={12}>
                                            <Routes>
                                                <Route path="/login" element={<Login/>}/>
                                                <Route path="/" element={<NewReservation/>}/>
                                                <Route path="/mijn-reservaties" element={<MyReservations/>}/>
                                                <Route path="/nieuwe-reservatie" element={<NewReservation/>}/>
                                                {/*<Route path="/nieuwe-reservatie-planner" element={<NewReservationPlanner/>}/>*/}
                                                <Route path="/mijn-dierenarts" element={<VeterinaryForm/>}/>
                                                <Route path="/mijn-profiel" element={<PersonForm reservationUuid={reservationUuid}/>}/>
                                                <Route path="/noodcontact" element={<EmergencyContactForm reservationUuid={reservationUuid}/>}/>
                                                <Route path="/mijn-katten" element={<CatInformationForm reservationUuid={reservationUuid}/>}/>
                                                <Route path="/reservatie-beheer" element={
                                                    <ProtectedRoute roles={['cat-admin']} element={<ReservationManagement/>}/>
                                                }/>
                                                <Route path="/servicefee" element={
                                                    <ProtectedRoute roles={['cat-admin']} element={<ServiceFee/>}/>
                                                }/>
                                                <Route path="/vacation" element={
                                                    <ProtectedRoute roles={['cat-admin']} element={<Vacations/>}/>
                                                }/>
                                                <Route path="/reservatie-overzicht" element={
                                                    <ProtectedRoute roles={['cat-admin']} element={<NewReservationsOverview/>}/>
                                                }/>
                                                <Route path="/reservatie-toevoegen" element={
                                                    <ProtectedRoute roles={['cat-admin']} element={<AddReservation/>}/>
                                                }/>
                                                <Route path="/pick-delivery-schedules" element={
                                                    <ProtectedRoute roles={['cat-admin']} element={<PickUpDeliverySchedules/>}/>
                                                }/>
                                                <Route path="/payment-processor" element={<PaymentProcessor/>}/>
                                                {/*<Route path="/checkout" element={<Checkout/>}/>*/}
                                                {/*<Route path="/checkout" element={<Checkout/>}/>*/}
                                            </Routes>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </GlobalStateProvider>
                        </Box>
                    </Auth0Provider>
                </Router>
            </ThemeProvider>
        </div>
    );
}

export default App;
