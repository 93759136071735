import {withAuthenticationRequired} from "@auth0/auth0-react";
import Loading from "../../common/Loading";
import React, {useContext, useEffect, useState} from "react";
import {
    Alert,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    FormControlLabel,
    Paper, Radio, RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {useReservationAPI} from "../../../service/useReservationAPI";
import {GlobalStateContext} from "../../../state/GlobalStateProvider";
import GetErrorSnackbar from "../../../common/Snackbar";
import {useTranslation} from "react-i18next";

function SelectStay({availableStays, updateStays, updateReservationStep, isLoadingInParent}) {
    const {t} = useTranslation();
    const {getStaysFromReservation} = useReservationAPI();
    const {globalState, setPartOfGlobalState} = useContext(GlobalStateContext);

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true)
        getStaysFromReservation(globalState.reservationUuid)
            .then(value => {
                if (value) {
                    updateStays(value);
                }
            })
            .catch(() => handleFetchError('ophalen van verblijf in reservatie is mislukt'))
            .finally(() => {
                setIsLoading(false)
            });
    }, []);

    function handleFetchError(reason) {
        setSnackbarMessage(reason)
        setOpenSnackbar(true)
    }

    const getLabel = (stay) => {
        const meerderVerblijven = {
            XL: t('multipleStayXL'),
            STANDARD: t('multipleStayStandard')
        }

        const eenVerblijf = {
            XL: t('singleStayXL'),
            STANDARD: t('singleStayStandard')
        }
        if (globalState.reservationMeta.catsInReservationIds.length > 1) {
            return `${meerderVerblijven[stay]}`
        } else {
            return `${eenVerblijf[stay]}`
        }
    }

    return (
        <>
            {isLoading && isLoadingInParent && (<Loading/>)}
            {!isLoading && !isLoadingInParent && (
                <Card>
                    <CardHeader title={<Typography color="secondary" variant="h5">{t('selectStayTitle')}</Typography>}/>
                    <CardContent>
                        {availableStays.length > 0 && (<TableContainer component={Paper} sx={{marginTop: 5}}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><b>{t('selectStay')}</b></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        name="radio-buttons-group"
                                    >
                                        {
                                            availableStays.map((stay) => (
                                                <TableRow key={stay} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                    <TableCell component="th" scope="row">
                                                        <FormControlLabel
                                                            checked={globalState.reservationMeta.stayTypes.includes(stay)}
                                                            value={stay}
                                                            control={<Radio/>}
                                                            label={getLabel(stay)}
                                                            onChange={() => {
                                                                setPartOfGlobalState('reservationMeta.stayTypes', [stay])
                                                            }}/>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </RadioGroup>
                                </TableBody>
                            </Table>
                        </TableContainer>)}

                        {!isLoading && !isLoadingInParent && availableStays.length === 0 && (
                            <Alert sx={{marginTop: 2}} severity="warning">{t('noAvailableStays')}</Alert>
                        )}
                    </CardContent>
                    <CardActions style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                        {availableStays.length > 0 && (
                            <>
                                <Button
                                    onClick={() => {
                                        setPartOfGlobalState('reservationMeta.stayTypes', [])
                                        updateReservationStep(2);
                                    }}
                                    variant="contained">
                                    {t('previous')}
                                </Button>
                                <Button disabled={globalState.reservationMeta.stayTypes.length === 0} onClick={() => updateReservationStep(4)} variant="contained">
                                    {t('next')}
                                </Button></>
                        )}

                        {availableStays.length === 0 && (<Button onClick={() => updateReservationStep(1)} variant="contained">
                            {t('updateDates')}
                        </Button>)}
                    </CardActions>
                    <GetErrorSnackbar openSnackbar={openSnackbar} snackbarMessage={snackbarMessage} setOpenSnackbar={setOpenSnackbar}/>
                </Card>)}
        </>
    )
}

export default withAuthenticationRequired(SelectStay, {
    onRedirecting: () => <Loading/>,
    returnTo: '/nieuwe-reservatie'
});
