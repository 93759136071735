import React, {useState} from "react";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    TextField,
    Grid,
    Typography,
    Box,
    InputAdornment, Card, CardHeader, CardContent, CardActions, Modal, Checkbox, MenuItem, Select, Divider, FormControlLabel, Radio, RadioGroup, Alert
} from "@mui/material";
import nl from 'date-fns/locale/nl';
import Loading from "../common/Loading";
import GetErrorSnackbar from "../../common/Snackbar";
import SearchIcon from '@mui/icons-material/Search';
import {useTranslation} from "react-i18next";
import {useReservationAdminAPI} from "../../service/useReservationAdminAPI";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {getDateStringToAvoidTimezone, parseFormattedDate, toLocalDate} from "../../common/DateUtil";
import {useReservationAPI} from "../../service/useReservationAPI";
import {addDays, parseISO} from "date-fns";

const AddReservation = () => {
    const {t} = useTranslation();
    const [inputValue, setInputValue] = useState('');
    const {searchByCatName, createReservation} = useReservationAdminAPI();
    const {checkReservationAvailability, getAvailableStays} = useReservationAPI();
    const [catInformations, setCatInformations] = useState([]);
    const [currentCatInformation, setCurrentCatInformation] = useState({cats: [], person: {}});
    const [newReservationDto, setNewReservationDto] = useState({
        personId: undefined, catsInReservationIds: [], fromDate: undefined, toDate: undefined, checkInHour: undefined, checkOutHour: undefined, stayTypes: []
    })
    const [stays, setStays] = useState([]);
    const [noStaysAvailable, setNoStaysAvailable] = useState(true)
    const [openEditModal, setOpenEditModal] = React.useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [severity, setSeverity] = useState('error');

    const handleCloseEditModal = () => {
        setOpenEditModal(false);
        setNewReservationDto({
                personId: undefined, catsInReservationIds: [], fromDate: undefined, toDate: undefined, checkInHour: undefined, checkOutHour: undefined, stayTypes: []
            }
        )
        setStays([])
        setNoStaysAvailable(true);
    }

    const handleAddOption = () => {
        if (inputValue) {
            searchByCatName(inputValue)
                .then(value => setCatInformations(value))
                .catch(() => {
                    handleFetchError(t('searchFailure'))
                })
        }
    };

    const handleSaveReservation = () => {
        createReservation(newReservationDto)
            .then(value => {
                handleCloseEditModal()
                handleSuccessMessage("createdReservationSuccessful");
            })
            .catch(() => {
                handleFetchError(t('createReservationFailed'))
            })
    }

    function handleFetchError(reason) {
        setSnackbarMessage(reason)
        setOpenSnackbar(true)
    }

    function handleSuccessMessage(reason) {
        setSnackbarMessage(reason)
        setSeverity("success")
        setOpenSnackbar(true)
    }

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleSelectChange = (event, field) => {
        console.log(event)

        let value = event.target ? event.target.value : event
        if (field === 'fromDate' || field === 'toDate') {
            value = getDateStringToAvoidTimezone(value);
        }
        console.log(value);

        setNewReservationDto({...newReservationDto, [field]: value})
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const toggleCatForReservation = (id) => {
        const foundReservedCat = newReservationDto.catsInReservationIds.find(value => value === id);

        if (foundReservedCat === undefined) {
            const catToAdd = currentCatInformation.cats.find(cat => cat.id === id);
            if (catToAdd) {
                setNewReservationDto({
                    ...newReservationDto,
                    catsInReservationIds: [...newReservationDto.catsInReservationIds, catToAdd.id],
                });
            }
        } else {
            const catsLeft = newReservationDto.catsInReservationIds.filter(value => value !== id);
            setNewReservationDto({
                ...newReservationDto,
                catsInReservationIds: catsLeft,
            });
        }
    };

    function checkAvailability() {
        checkReservationAvailability(newReservationDto.fromDate, newReservationDto.toDate, newReservationDto.catsInReservationIds.length)
            .then(() => {
                getAvailableStays(newReservationDto.fromDate, newReservationDto.toDate)
                    .then(value => {
                        setStays(value)
                        setNoStaysAvailable(value.length > 0)
                    })
                    .catch(() => handleFetchError('fetchAvailableStaysFailed'));

            })
            .catch(() => handleFetchError('checkAvailabilityFailed'))
    }

    const getLabel = (stay) => {
        const meerderVerblijven = {
            XL: t('multipleStayXL'),
            STANDARD: t('multipleStayStandard')
        }

        const eenVerblijf = {
            XL: t('singleStayXL'),
            STANDARD: t('singleStayStandard')
        }
        if (newReservationDto.catsInReservationIds.length > 1) {
            return `${meerderVerblijven[stay]}`
        } else {
            return `${eenVerblijf[stay]}`
        }
    }

    return (
        <Grid container spacing={2} style={{padding: '100px'}}>
            <Grid item xs={12}>
                <Typography variant="h6" style={{marginBottom: '20px'}}>{t("addReservation")}</Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    required
                    value={inputValue}
                    onChange={handleInputChange}
                    label={t('catNameLabel')}
                    variant="outlined"
                    InputProps={{
                        endAdornment: (<InputAdornment position="end">
                            <Button onClick={handleAddOption} variant="contained"><SearchIcon/></Button>
                        </InputAdornment>),
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table size="small" aria-label="service fee table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("catName")}</TableCell>
                                <TableCell>{t("ownerName")}</TableCell>
                                <TableCell>{t("familie")}</TableCell>
                                <TableCell>{t("actions")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {catInformations.map((catInformation, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        {catInformation.name}
                                    </TableCell>
                                    <TableCell>
                                        {catInformation.person.forename + ' ' + catInformation.person.name}
                                    </TableCell>
                                    <TableCell>
                                        {catInformation.cats.map(cat => cat.name).join(', ')}
                                    </TableCell>
                                    <TableCell>
                                        <Button onClick={() => {
                                            setCurrentCatInformation(catInformation)
                                            setOpenEditModal(true)
                                            setNewReservationDto({...newReservationDto, personId: catInformation.person.id})
                                        }} color="primary">
                                            {t('addReservation')}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <GetErrorSnackbar openSnackbar={openSnackbar} snackbarMessage={snackbarMessage} setOpenSnackbar={setOpenSnackbar} severity={severity}/>

            <Modal
                open={openEditModal}
                onClose={handleCloseEditModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Card sx={style}>
                    <CardHeader title={<Typography color="secondary" variant="h5">{t('addReservation')}</Typography>}/>
                    <CardContent style={{display: 'flex', justifyContent: 'space-between'}}>
                        <Box>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                {currentCatInformation.person.forename + ' ' + currentCatInformation.person.name}
                            </Typography>
                            <Divider style={{marginBottom: 20}}/>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                {t('selectCats')}
                            </Typography>
                            {currentCatInformation.cats.map((cat) => (
                                <TableRow key={cat.id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell component="th" scope="row">
                                        <Checkbox
                                            checked={newReservationDto.catsInReservationIds.some(value => value === cat.id)}
                                            onChange={() => toggleCatForReservation(cat.id)}
                                            name="spayedNeutered"
                                            color="primary"
                                        />
                                        {cat.name}
                                    </TableCell>
                                </TableRow>
                            ))}

                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={nl}>
                                <DatePicker
                                    label={t("start_date")}
                                    // value={parseDate(fee.startDate)}
                                    onChange={(newValue) => handleSelectChange(newValue, 'fromDate')}
                                    inputFormat="dd-MM-yyyy"
                                    renderInput={(params) => <TextField {...params} fullWidth/>}
                                />
                            </LocalizationProvider>

                            <Select
                                value={newReservationDto.checkInHour}
                                displayEmpty
                                inputProps={{'aria-label': 'Without label'}}
                                sx={{width: 100}}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 200,
                                        },
                                    },
                                }}
                                renderValue={(value) => {
                                    if (!value) {
                                        return <em>{t('hour')}</em>;
                                    }
                                    return value;
                                }}
                                onChange={(event) => handleSelectChange(event, 'checkInHour')}
                            >
                                {Array.from({length: 38}, (_, i) => {
                                    const hour = 5 + Math.floor(i / 2);
                                    const minute = i % 2 === 0 ? '00' : '30';
                                    return `${hour.toString().padStart(2, '0')}:${minute}`;
                                }).map(option => (
                                    <MenuItem key={option} value={option}>{option}</MenuItem>
                                ))}
                            </Select>


                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={nl}>
                                <DatePicker
                                    label={t("end_date")}
                                    minDate={addDays(parseISO(newReservationDto.fromDate), 1)}
                                    onChange={(newValue) => handleSelectChange(newValue, 'toDate')}
                                    renderInput={(params) => <TextField {...params} fullWidth/>}
                                />
                            </LocalizationProvider>

                            <Select
                                value={newReservationDto.checkOutHour}
                                displayEmpty
                                inputProps={{'aria-label': 'Without label'}}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 200,
                                        },
                                    },
                                }}
                                sx={{width: 100}}
                                renderValue={(value) => {
                                    if (!value) {
                                        return <em>{t('hour')}</em>;
                                    }
                                    return value;
                                }}
                                onChange={(event) => handleSelectChange(event, 'checkOutHour')}
                            >
                                {Array.from({length: 38}, (_, i) => {
                                    const hour = 5 + Math.floor(i / 2);
                                    const minute = i % 2 === 0 ? '00' : '30';
                                    const seconds = '00';
                                    return `${hour.toString().padStart(2, '0')}:${minute}:${seconds}`;
                                }).map(option => (
                                    <MenuItem key={option} value={option}>{option}</MenuItem>
                                ))}
                            </Select>

                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="radio-buttons-group"
                            >
                                {
                                    stays.map((stay) => (
                                        <TableRow key={stay} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                            <TableCell component="th" scope="row">
                                                <FormControlLabel
                                                    checked={newReservationDto.stayTypes.includes(stay)}
                                                    value={stay}
                                                    control={<Radio/>}
                                                    label={getLabel(stay)}
                                                    onChange={() => {
                                                        setNewReservationDto({...newReservationDto, stayTypes: [stay]})
                                                    }}/>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </RadioGroup>
                            {!noStaysAvailable && (
                                <Alert sx={{marginTop: 2}} severity="warning">{t('noAvailableStays')}</Alert>
                            )}
                        </Box>
                    </CardContent>
                    <CardActions style={{display: 'flex', justifyContent: 'space-between'}}>
                        <Button onClick={handleCloseEditModal}>{t('cancel')}</Button>
                        <Button onClick={checkAvailability} disabled={!newReservationDto.fromDate || !newReservationDto.toDate || newReservationDto.catsInReservationIds.length < 1}>{t('Beschikbaarheid nakijken')}</Button>
                        <Button onClick={handleSaveReservation}
                                disabled={newReservationDto.stayTypes.length < 1 || newReservationDto.catsInReservationIds.length < 1 ||
                                    !newReservationDto.fromDate || !newReservationDto.toDate || !newReservationDto.checkInHour || !newReservationDto.checkOutHour}>
                            {t('save')}</Button>
                    </CardActions>
                </Card>
            </Modal>

        </Grid>
    );
};

export default withAuthenticationRequired(AddReservation, {
    onRedirecting: () => <Loading/>,
    returnTo: '/login'
});
